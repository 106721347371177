/*================================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
  	- Accordion
  	- Button
    - Category
    - Client
    - Countdown
    - Counter
    - Course
    - Events
    - Feature Info
    - Form
    - Gallery
    - List Style
    - Owl Carousel
    - Portfolio
    - Pricing Table
    - Select Dropdown
    - Skill
    - Social Icon
    - Swiper Slider
    - Tab
    - Team
    - Testimonial
 :: Header
   - Header Style 02
   - Header Transparent
 :: Layout
   - Section Title
   - Sticky column
 :: Blog
 :: Not Found
 :: Footer
 :: Responsive

======================================
[ End table content ]
======================================*/
/*****************************
  Typography
*****************************/

@font-face {
  font-family: "tex-gyre-adventor.bold-italic";
  src: url("../fonts/tex-gyre-adventor.bold-italic.woff") format("woff");
}

@font-face {
  font-family: "tex-gyre-adventor.bold";
  src: url("../fonts/tex-gyre-adventor.bold.woff") format("woff");
}

@font-face {
  font-family: "tex-gyre-adventor.italic";
  src: url("../fonts/tex-gyre-adventor.italic.woff") format("woff");
}

@font-face {
  font-family: "tex-gyre-adventor.regular";
  src: url("../fonts/tex-gyre-adventor.regular.woff") format("woff");
}

@font-face {
  font-family: "";
  src: url("../fonts/digitalis.ttf") format("truetype");
}

body {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  line-height: 1.5;
  color: #1e1e1e;
  overflow-x: hidden;
  overflow-y: auto;
}

.h-color h4 {
  color: #ed1c24 !important;
}

a {
  outline: medium none !important;
  color: #ed1c24;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:focus {
  color: #a61d37;
  text-decoration: none !important;
}

a:hover {
  color: #ed1c24;
  text-decoration: none !important;
}

input {
  outline: medium none !important;
  color: #a61d37;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Poppins" !important;
  font-weight: normal;
  color: #1a2844;
  margin-top: 0px;
  font-weight: 500;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 52px;
  font-style: normal;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 30px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 16px;
}

.bradCrumBg {
  background-image: url("./../images//breadcum/01.jpg");
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.8;
}

.bg-primary h1,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary h6,
.bg-primary p {
  color: #ffffff;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark p {
  color: #ffffff;
}

.bg-primary-gradient h1,
.bg-primary-gradient h2,
.bg-primary-gradient h3,
.bg-primary-gradient h4,
.bg-primary-gradient h5,
.bg-primary-gradient h6,
.bg-primary-gradient p {
  color: #ffffff;
}

section {
  position: relative;
}

label {
  font-weight: normal;
}

.lead {
  font-weight: 400;
  font-size: 19px;
}

*::-moz-selection {
  background: #a61d37;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #a61d37;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #ed1c24;
  color: #ffffff;
  text-shadow: none;
}

/* form-control */
.form-control {
  border: none;
  background-color: #ffffff;
  height: 48px;
  padding: 10px 55px 10px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  color: #b7b7b7;
  border-radius: 5px 0px 0px 5px !important;
  background-clip: inherit !important;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #a61d37;
  background-color: #f4f4f5;
}

.form-control::-moz-placeholder {
  color: #b7b7b7;
}

.form-control::-ms-input-placeholder {
  color: #b7b7b7;
}

.form-control::-webkit-input-placeholder {
  color: #b7b7b7;
}

/* form-dark */
.form-dark .form-control {
  background-color: #ffffff;
}

.form-dark .form-control:focus {
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  border-color: #a61d37;
}

/*-----------------------------------------------------------------------------------------*/
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

/* form bg white */
.bg-white .form-control {
  background: #f6f6f6;
}

.form-dark .form-control::-moz-placeholder {
  color: #16243e;
}

.form-dark .form-control::-ms-input-placeholder {
  color: #16243e;
}

.form-dark .form-control::-webkit-input-placeholder {
  color: #16243e;
}

.form-dark .custom-checkbox .custom-control-label:before {
  border: 1px solid #c1c1c1;
}

/* custom-file */
.custom-file {
  height: 50px;
}

.custom-file .custom-file-input {
  height: 50px;
  color: #1a2844;
  border-radius: 5px;
  border-color: #eeeeee;
}

.custom-file .custom-file-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #a61d37;
}

.custom-file .custom-file-label {
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #1a2844;
  border-color: #eeeeee;
  padding: 15px 90px 15px 20px;
  border-radius: 5px;
}

.custom-file .custom-file-label:after {
  height: 48px;
  padding: 14px 25px;
}

.custom-file-input:focus~.custom-file-label {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #a61d37;
}

/* input-group-prepend */
.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  background: transparent;
  border-color: #eeeeee;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* checkbox */
.custom-control-input:checked~.custom-control-label:before {
  background: #a61d37;
  border-color: #a61d37;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
  background: transparent;
  border-color: transparent;
}

.custom-control-input:focus~.custom-control-label:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
  border-color: #eeeeee;
}

.custom-control-label:before {
  top: 2px;
}

.custom-control-label:after {
  top: 2px;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 5px;
  border: 2px solid #f4f4f5;
}

.form-group .form-radio .custom-control-input:checked~.custom-control-label::after {
  background: #1a2844;
  border-color: #1a2844;
  border-radius: 50%;
}

/* border */
.border {
  border-color: #eeeeee !important;
}

.border-top {
  border-top-color: #eeeeee !important;
}

.border-left {
  border-left-color: #eeeeee !important;
}

.border-right {
  border-right-color: #eeeeee !important;
}

.border-bottom {
  border-bottom-color: #eeeeee !important;
}

.border-primary {
  border-color: #a61d37 !important;
}

.border-dark {
  border-color: #1a2844 !important;
}

.border-light {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

/* border-radius */
.border-radius {
  border-radius: 5px !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-radius-left-none {
  border-radius: 0 3px 3px 0;
}

.border-radius-right-none {
  border-radius: 3px 0 0 3px;
}

/* badge */
.badge+.badge {
  margin-left: 6px;
}

.badge-md {
  padding: 5px 8px;
  font-size: 13px;
  font-weight: normal;
}

.badge-primary {
  background: #a61d37;
}

.breadcrumb {
  padding-left: 0;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
}

.breadcrumb .breadcrumb-item {
  color: #ffffff;
}

.breadcrumb .breadcrumb-item a {
  color: #ffffff;
}

.breadcrumb .breadcrumb-item a:hover {
  color: #ed1c24;
}

.breadcrumb .breadcrumb-item:before {
  color: #ffffff !important;
}

.breadcrumb .active {
  color: #ed1c24 !important;
}

/*****************************
  Responsive
*****************************/
@media (max-width: 991px) {

  /* Typography */
  h1,
  .h1 {
    font-size: 48px;
  }

  h2,
  .h2 {
    font-size: 30px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {

  /* Typography */
  h1,
  .h1 {
    font-size: 42px;
    font-style: normal;
  }

  h2,
  .h2 {
    font-size: 28px;
  }
}

@media (max-width: 479px) {

  /* Typography */
  h1,
  .h1 {
    font-size: 32px;
    font-style: normal;
  }

  h2,
  .h2 {
    font-size: 26px;
  }

  h3,
  .h3 {
    font-size: 20px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }

  h6,
  .h6 {
    font-size: 14px;
  }
}

/*****************************
  Helper Classes
*****************************/
/* Text Color */
#ed1c24 {
  color: #ed1c24 !important;
}

#ed1c242 {
  color: #ed1c24 !important;
}

.text-dark {
  color: #1a2844 !important;
}

.text-light {
  color: #f6f6f6 !important;
}

.text-muted {
  color: #16243e !important;
}

.text-gradient {
  background: -webkit-linear-gradient(#a61d37, #fa3a72);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Background Color */
.bg-primary {
  background: #a61d37 !important;
}

.bg-dark {
  background: #1a2844 !important;
}

.bg-gray {
  background: #f4f4f5 !important;
}

.bg-light {
  background: #245898 !important;
}

.bg-primary-gradient {
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(30%, #a61d37),
      to(#fa3a72)) !important;
  background: linear-gradient(to right, #a61d37 30%, #fa3a72 100%) !important;
}

.bg-dark-gradient {
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(10%, #1a2844),
      to(#1a2844)) !important;
  background: linear-gradient(to right, #1a2844 10%, #1a2844 100%) !important;
}

/* fill */
.fill-primary {
  fill: #a61d37;
  fill-rule: evenodd;
}

.fill-dark {
  fill: #1a2844;
  fill-rule: evenodd;
}

.fill-gray {
  fill: #f4f4f5;
  fill-rule: evenodd;
}

.fill-light {
  fill: #f6f6f6;
  fill-rule: evenodd;
}

.fill-white {
  fill: #ffffff;
  fill-rule: evenodd;
}

.fill-gradient {
  fill: url(#Gradient);
  fill-rule: evenodd;
}

.fill-gradient-02 {
  fill: url(#Gradient-02);
  fill-rule: evenodd;
}

.fill-gradient-1 {
  stop-color: #a61d37;
}

.fill-gradient-2 {
  stop-color: #fa3a72;
}

/* Page section margin padding */
.space-lg-ptb {
  padding: 160px 0;
}

.space-lg-pt {
  padding-top: 160px;
}

.space-lg-pb {
  padding-bottom: 160px;
}

.space-ptb {
  padding: 100px 0;
}

.space-pt {
  padding-top: 100px;
}

.space-pb {
  padding-bottom: 100px;
}

.space-sm-ptb {
  padding: 70px 0;
}

.space-sm-pt {
  padding-top: 70px;
}

.space-sm-pb {
  padding-bottom: 70px;
}

.space-lg-mt {
  margin-top: 150px;
}

/* position */
.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.top-50 {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.left-50 {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* z index */
.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

.z-index-999 {
  z-index: 999;
}

.z-index-n1 {
  z-index: -1;
}

/* Opacity */
.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

/* Font Size */
.display-1 {
  font-size: 9rem;
  line-height: 1;
  font-weight: 800;
}

.display-2 {
  font-size: 8rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-3 {
  font-size: 7rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-4 {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 800;
}

.display-5 {
  font-size: 5rem;
  line-height: 1.2;
}

.display-6 {
  font-size: 4rem;
  line-height: 1.2;
}

.display-7 {
  font-size: 3rem;
  line-height: 1.2;
}

.display-8 {
  font-size: 2.5rem;
  line-height: 1.2;
}

.display-9 {
  font-size: 1.5rem;
  line-height: 1.4;
}

.display-10 {
  font-size: 1rem;
  line-height: 1.4;
}

/* Overflow hidden */
.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/*****************************
  Display Responsive
*****************************/
@media (max-width: 1199px) {

  /* Font Size */
  .display-1 {
    font-size: 8rem;
  }

  .display-2 {
    font-size: 7rem;
  }

  .display-3 {
    font-size: 6rem;
  }

  .display-4 {
    font-size: 5rem;
  }

  .display-5 {
    font-size: 4.5rem;
  }

  .display-6 {
    font-size: 4rem;
  }

  .display-7 {
    font-size: 2.5rem;
  }

  .display-8 {
    font-size: 2rem;
  }
}

@media (max-width: 991px) {

  /* Font Size */
  .display-1 {
    font-size: 7rem;
  }

  .display-2 {
    font-size: 6rem;
  }

  .display-3 {
    font-size: 5rem;
  }

  .display-4 {
    font-size: 4.5rem;
  }

  .display-5 {
    font-size: 4rem;
  }

  .display-6 {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {

  /* Font Size */
  .display-1 {
    font-size: 6rem;
  }

  .display-2 {
    font-size: 5rem;
  }

  .display-3 {
    font-size: 4rem;
  }

  .display-4 {
    font-size: 3.5rem;
  }

  .display-5 {
    font-size: 3rem;
  }

  .display-6 {
    font-size: 2.5rem;
  }

  .display-7 {
    font-size: 2.3rem;
  }

  .display-9 {
    font-size: 1.3rem;
  }
}

@media (max-width: 575px) {

  /* Font Size */
  .display-1 {
    font-size: 5rem;
  }

  .display-2 {
    font-size: 4rem;
  }

  .display-3 {
    font-size: 3rem;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .display-5 {
    font-size: 2rem;
  }

  .display-6 {
    font-size: 1.8rem;
  }

  .display-7 {
    font-size: 1.6rem;
  }

  .display-8 {
    font-size: 1.5rem;
  }

  .display-9 {
    font-size: 1.2rem;
  }
}

/* Height */
.h-100vh {
  height: 100vh !important;
}

.h-900 {
  height: 900px;
}

.h-800 {
  height: 800px;
}

.h-700 {
  height: 700px;
}

.h-600 {
  height: 600px;
}

.h-500 {
  height: 500px;
}

.h-400 {
  height: 400px;
}

.h-300 {
  height: 300px;
}

/*****************************
  Height Responsive
*****************************/
@media (max-width: 1199px) {

  /* Height */
  .h-lg-800 {
    height: 800px;
  }

  .h-lg-700 {
    height: 700px;
  }

  .h-lg-600 {
    height: 600px;
  }

  .h-lg-500 {
    height: 500px;
  }

  .h-lg-400 {
    height: 400px;
  }

  .h-lg-300 {
    height: 300px;
  }
}

@media (max-width: 991px) {

  /* Height */
  .h-md-800 {
    height: 800px;
  }

  .h-md-700 {
    height: 700px;
  }

  .h-md-600 {
    height: 600px;
  }

  .h-md-500 {
    height: 500px;
  }

  .h-md-400 {
    height: 400px;
  }

  .h-md-300 {
    height: 300px;
  }

  .h-md-auto {
    height: auto;
  }
}

@media (max-width: 767px) {

  /* Height */
  .h-sm-600 {
    height: 600px;
  }

  .h-sm-500 {
    height: 500px;
  }

  .h-sm-400 {
    height: 400px;
  }

  .h-sm-300 {
    height: 300px;
  }

  .h-sm-auto {
    height: auto;
  }
}

/* padding */
.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

/* margin */
.m-6 {
  margin: 7rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 7rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 7rem !important;
}

.mt-6,
.my-6 {
  margin-top: 7rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 7rem !important;
}

.m-7 {
  margin: 9rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 9rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 9rem !important;
}

.mt-7,
.my-7 {
  margin-top: 9rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 9rem !important;
}

.m-8 {
  margin: 10rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 10rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 10rem !important;
}

.mt-8,
.my-8 {
  margin-top: 10rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 10rem !important;
}

.m-9 {
  margin: 12rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 12rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 12rem !important;
}

.mt-9,
.my-9 {
  margin-top: 12rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 12rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 14rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 14rem !important;
}

.mt-10,
.my-10 {
  margin-top: 14rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 14rem !important;
}

.m-n6 {
  margin: -7rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -7rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -7rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -7rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -7rem !important;
}

.m-n7 {
  margin: -9rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -9rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -9rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -9rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -9rem !important;
}

.m-n8 {
  margin: -10rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -10rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -10rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -10rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -10rem !important;
}

.m-n9 {
  margin: -12rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -12rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -12rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -12rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -12rem !important;
}

.m-n10 {
  margin: -14rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -14rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -14rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -14rem !important;
}

@media (min-width: 576px) {

  /* Margin */
  .m-sm-n6 {
    margin: -7rem !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -7rem !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -7rem !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -7rem !important;
  }

  .m-sm-n7 {
    margin: -9rem !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -9rem !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -9rem !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -9rem !important;
  }

  .ml-sm-n9 {
    margin-left: -12rem !important;
  }

  .ml-sm-n10 {
    margin-left: -14rem !important;
  }

  /* Padding */
  .p-sm-6 {
    padding: 5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pr-sm-6,
  .py-sm-6 {
    padding-right: 5rem !important;
  }

  /* border */
  .border-sm-0 {
    border: 0 !important;
  }

  .border-sm-top {
    border-top: 1px solid #eeeeee !important;
  }

  .border-sm-right {
    border-right: 1px solid #eeeeee !important;
  }

  .border-sm-bottom {
    border-bottom: 1px solid #eeeeee !important;
  }

  .border-sm-left {
    border-left: 1px solid #eeeeee !important;
  }
}

@media (min-width: 768px) {

  /* Margin */
  .m-md-n6 {
    margin: -7rem !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -7rem !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -7rem !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -7rem !important;
  }

  .m-md-n7 {
    margin: -9rem !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -9rem !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -9rem !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -9rem !important;
  }

  .ml-md-n9 {
    margin-left: -12rem !important;
  }

  .ml-md-n10 {
    margin-left: -14rem !important;
  }

  /* Padding */
  .py-md-0 {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  /* border */
  .border-md-0 {
    border: 0 !important;
  }

  .border-md-top {
    border-top: 1px solid #eeeeee !important;
  }

  .border-md-right {
    border-right: 1px solid #eeeeee !important;
  }

  .border-md-bottom {
    border-bottom: 1px solid #eeeeee !important;
  }

  .border-md-left {
    border-left: 1px solid #eeeeee !important;
  }
}

@media (min-width: 992px) {

  /* Margin */
  .m-lg-n6 {
    margin: -7rem !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -7rem !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -7rem !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -7rem !important;
  }

  .m-lg-n7 {
    margin: -9rem !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -9rem !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -9rem !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -9rem !important;
  }

  .ml-lg-n9 {
    margin-left: -12rem !important;
  }

  .ml-lg-n10 {
    margin-left: -14rem !important;
  }

  /* Padding */
  .p-lg-6 {
    padding: 5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }

  /* border */
  .border-lg-0 {
    border: 0 !important;
  }

  .border-lg-top {
    border-top: 1px solid #eeeeee !important;
  }

  .border-lg-right {
    border-right: 1px solid #eeeeee !important;
  }

  .border-lg-bottom {
    border-bottom: 1px solid #eeeeee !important;
  }

  .border-lg-left {
    border-left: 1px solid #eeeeee !important;
  }
}

@media (min-width: 1200px) {

  /* Margin */
  .m-xl-n6 {
    margin: -7rem !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -7rem !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -7rem !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -7rem !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -7rem !important;
  }

  .m-xl-n7 {
    margin: -9rem !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -9rem !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -9rem !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -9rem !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -9rem !important;
  }

  .ml-xl-n9 {
    margin-left: -12rem !important;
  }

  .ml-xl-n10 {
    margin-left: -14rem !important;
  }

  /* Padding */
  .p-xl-6 {
    padding: 5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }

  /* border */
  .border-xl-0 {
    border: 0 !important;
  }

  .border-xl-top {
    border-top: 1px solid #eeeeee !important;
  }

  .border-xl-right {
    border-right: 1px solid #eeeeee !important;
  }

  .border-xl-bottom {
    border-bottom: 1px solid #eeeeee !important;
  }

  .border-xl-left {
    border-left: 1px solid #eeeeee !important;
  }
}

/* pre-loader */
#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}

#pre-loader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto;
}

/* Background overlay */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-overlay-black-10 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-10:before {
  background: rgba(26, 40, 68, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-20:before {
  background: rgba(26, 40, 68, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-30 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-30:before {
  background: rgba(26, 40, 68, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-40 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-40:before {
  background: rgba(26, 40, 68, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-50:before {
  background: rgba(26, 40, 68, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-60 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-60:before {
  background: rgba(26, 40, 68, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-70:before {
  background: rgb(0 0 0 / 55%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-80 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-80:before {
  background: rgba(26, 40, 68, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-90:before {
  background: rgb(192 47 47 / 90%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-10 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-10:before {
  background: rgba(255, 255, 255, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-20:before {
  background: rgba(255, 255, 255, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-30 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-30:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-40 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-40:before {
  background: rgba(255, 255, 255, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-50:before {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-60 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-60:before {
  background: rgba(255, 255, 255, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-70:before {
  background: rgba(255, 255, 255, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-80 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-80:before {
  background: rgba(255, 255, 255, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-90:before {
  background: rgba(255, 255, 255, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-primary-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-primary-20:before {
  background: rgba(166, 29, 55, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-primary-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-primary-50:before {
  background: rgba(166, 29, 55, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-primary-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-primary-70:before {
  background: rgba(166, 29, 55, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-primary-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-primary-90:before {
  background: rgba(166, 29, 55, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/* avatar */
.avatar {
  width: 50px;
  height: 50px;
  display: inline-block;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatar-sm {
  width: 20px;
  height: 20px;
}

.avatar.avatar-md {
  width: 80px;
  height: 80px;
}

.avatar.avatar-lg {
  width: 145px;
  height: 145px;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.grayscale .grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.grayscale:hover {
  -webkit-filter: inherit;
  filter: inherit;
}

/* Contact-info */
.contact-info ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}

.contact-info ul li:last-child {
  margin-bottom: 0;
}

.contact-info ul li span {
  margin-left: 15px;
}

.contact-info ul li i {
  margin-top: 5px;
}

.post-navigation .nav-previous a {
  padding: 0;
  text-align: left;
}

.post-navigation .nav-previous a span {
  margin-left: 0;
  margin-right: 15px;
}

.post-navigation .nav-previous a span:before {
  right: auto;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.post-navigation .nav-previous a span:after {
  right: auto;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.post-navigation .nav-previous a:hover {
  color: #a61d37;
}

.post-navigation .nav-previous a:focus {
  color: #a61d37;
}

.post-navigation .nav-previous a:active {
  color: #a61d37;
}

.post-navigation .nav-next a {
  padding: 0;
  text-align: right;
}

.post-navigation .nav-next a:hover {
  color: #a61d37;
}

.post-navigation .nav-next a:focus {
  color: #a61d37;
}

.post-navigation .nav-next a:active {
  color: #a61d37;
}

.post-navigation .nav-title {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
  display: inline-block;
}

.post-tags a {
  color: #666666;
}

.post-tags a:hover {
  color: #a61d37;
}

@media (max-width: 991px) {

  /* Page section margin padding */
  .space-lg-ptb {
    padding: 100px 0;
  }

  .space-lg-pt {
    padding-top: 100px;
  }

  .space-lg-pb {
    padding-bottom: 100px;
  }

  .space-ptb {
    padding: 70px 0;
  }

  .space-pt {
    padding-top: 70px;
  }

  .space-pb {
    padding-bottom: 70px;
  }

  .space-sm-ptb {
    padding: 50px 0;
  }

  .space-sm-pt {
    padding-top: 50px;
  }

  .space-sm-pb {
    padding-bottom: 50px;
  }

  .space-lg-mt {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {

  /* container */
  .container {
    max-width: 100%;
  }

  /* Page section margin padding */
  .space-lg-ptb {
    padding: 70px 0;
  }

  .space-lg-pt {
    padding-top: 70px;
  }

  .space-lg-pb {
    padding-bottom: 70px;
  }

  .space-ptb {
    padding: 50px 0;
  }

  .space-pt {
    padding-top: 50px;
  }

  .space-pb {
    padding-bottom: 50px;
  }

  .space-sm-ptb {
    padding: 30px 0;
  }

  .space-sm-pt {
    padding-top: 30px;
  }

  .space-sm-pb {
    padding-bottom: 30px;
  }

  .space-lg-mt {
    margin-top: 70px;
  }
}

/*** SVG IE11 specific styles ***/
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .clipped-title {
    background-image: none;
  }

  .team.team-02 .team-img .shape-04 {
    height: 168px;
  }

  .bg-primary-gradient .counter .counter-icon i {
    color: #1a2844;
    background: transparent !important;
  }

  .feature-step-item.bg-primary .step-number {
    background: transparent !important;
    color: #ffffff;
  }
}

/*****************************
  Shortcodes
*****************************/
/*****************************
  Accordion
*****************************/
.accordion .card {
  border: none;
}

.accordion .card:last-child .card-header {
  margin-bottom: 0;
}

.accordion .card-body {
  padding: 20px 30px 30px 30px;
}

.accordion .card-body ul li {
  margin-bottom: 5px;
}

.accordion .card-header {
  background: none;
  border-bottom: none;
  padding: 0;
}

.accordion>.card .card-header {
  margin-bottom: 10px;
}

.accordion .accordion-icon.card-header button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ed1c24;
  font-size: 18px;
  color: #ffffff;
  padding: 20px;
  width: 100%;
  text-align: left;
  border: none;
  font-weight: normal;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
}

.accordion .accordion-icon.card-header .btn:after {
  content: "\f078";
  margin-left: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  font-size: 14px;
}

.accordion .accordion-icon.card-header button.collapsed {
  background: #ed1c24;
}

.accordion .accordion-icon.card-header button.collapsed:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion .card-header .btn:before {
  content: none;
  position: inherit;
}

.accordion .card-header .btn:after {
  content: none;
  position: inherit;
}

/*****************************
  Button
*****************************/
button {
  outline: medium none !important;
  color: #a61d37;
  font-family: digitalis;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* btn */
.btn {
  font-size: 16px;
  font-weight: 700;
  padding: 12px 28px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: digitalis;
}

.btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn-primary.dropdown-toggle {
  background: #a61d37;
  border-color: #a61d37;
}

/* btn-primary */
.btn-primary {
  background: #ed1c24 !important;
  border-color: #ed1c24 !important;
  color: #ffffff;
}

.btn-primary:hover {
  background: #212529 !important;
  border-color: #212529 !important;
  color: #ffffff;
}

.btn-primary:hover:not(:disabled):not(.disabled).active {
  background: #a61d37;
  border-color: #a61d37;
}

.btn-primary:hover:not(:disabled):not(.disabled):active {
  background: #a61d37;
  border-color: #a61d37;
}

.btn-primary:focus {
  background: #a61d37;
  border-color: #a61d37;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #a61d37;
  border-color: #a61d37;
}

/* btn-dark */
.btn-dark {
  /*background: #1a2844;
  border-color: #1a2844;*/
  background: #019441;
  border-color: #019340;
}

.btn-dark:hover {
  background: #213356;
  border-color: #213356;
}

.btn-dark:not(:disabled):not(.disabled):active:focus {
  color: #ffffff;
}

.btn:not(:disabled):not(.disabled).active:focus {
  color: #ffffff;
}

.btn i {
  padding: 0px 10px 0px 0px;
}

.btn+.btn {
  margin-left: 3px;
}

.btn-link {
  color: #a61d37;
}

.btn-link:hover {
  color: #1a2844;
  text-decoration: none;
  outline: none;
}

.btn-link:focus {
  color: #1a2844;
  text-decoration: none;
  outline: none;
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
  color: #1a2844;
}

/* btn-white */
.btn-white {
  background: #ffffff;
  border-color: #ffffff;
  color: #1a2844;
}

.btn-white:hover {
  background: #f4f4f5;
  border-color: #f4f4f5;
  color: #1a2844;
}

.btn-white:active {
  color: #1a2844;
}

.btn-white:focus {
  color: #1a2844;
}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
  color: #1a2844;
}

/* btn-light */
.btn-light:active {
  color: #1a2844;
}

.btn-light:focus {
  color: #1a2844;
}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
  color: #1a2844;
}

/* btn-Size */
.btn.btn-sm {
  padding: 6px 16px;
}

.btn.btn-md {
  padding: 8px 30px;
}

.btn.btn-lg {
  padding: 12px 35px;
  font-size: 18px;
}

.btn.btn-xl {
  padding: 15px 60px;
  font-size: 18px;
}

/* btn-outline-secondary */
.btn-outline-secondary {
  border: 2px solid #eeeeee;
  color: #1a2844;
}

.btn-outline-secondary:hover {
  background: #1a2844;
  color: #ffffff;
  border-color: #1a2844;
}

.btn-outline-secondary:focus {
  background: #1a2844;
  color: #ffffff;
  border-color: #1a2844;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #1a2844;
  color: #ffffff;
  border-color: #1a2844;
}

.btn-outline-primary {
  color: #a61d37;
  border-color: #a61d37;
}

.btn-outline-primary:hover {
  background: #a61d37;
  color: #ffffff;
  border-color: #a61d37;
}

.btn-outline-primary:focus {
  background: #a61d37;
  color: #ffffff;
  border-color: #a61d37;
}

.about-section {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #a61d37;
  color: #ffffff;
  border-color: #a61d37;
}

/* btn-arrow */
.btn-arrow {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}

.btn-arrow span {
  margin-left: 15px;
  width: 18px;
  height: 2px;
  display: inline-block;
  position: relative;
  background: #a61d37;
  -webkit-transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
  transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
}

.btn-arrow span:before {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -4px;
  right: 0px;
  width: 10px;
}

.btn-arrow span:after {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 10px;
  bottom: -4px;
  right: 0px;
}

.btn-arrow:hover span {
  width: 50px;
}

.btn-white.btn-arrow span {
  background: #1a2844;
}

.btn-dark.btn-arrow span {
  background: #ffffff;
}

.btn-primary-gradient.btn-arrow span {
  background: #ffffff;
}

.btn-primary.btn-arrow span {
  background: #ffffff;
}

.btn-arrow.arrow-gradient span {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(50%, #a61d37),
      to(#fa3a72));
  background-image: linear-gradient(to right, #a61d37 50%, #fa3a72 100%);
}

.btn-arrow.arrow-white span {
  background: #ffffff;
}

.btn-arrow.arrow-dark span {
  background: #1a2844;
}

/* btn-gradient */
.btn-primary-gradient {
  background-size: 200% auto;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(5%, #a61d37),
      color-stop(51%, #fa3a72),
      to(#a61d37));
  background-image: linear-gradient(to right,
      #a61d37 5%,
      #fa3a72 51%,
      #a61d37 100%);
  color: #ffffff;
}

.btn-primary-gradient:hover {
  background-position: right center;
  color: #ffffff;
}

.btn-dark-gradient {
  background-size: 200% auto;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(5%, #161427),
      color-stop(51%, #592036),
      to(#161427));
  background-image: linear-gradient(to right,
      #161427 5%,
      #592036 51%,
      #161427 100%);
  color: #ffffff;
}

.btn-dark-gradient:hover {
  background-position: right center;
  color: #ffffff;
}

/* video-btn */
.video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-btn i {
  font-size: 22px;
  padding-right: 0;
}

/* video-btn */
.video-btn-02 {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-btn-02 i {
  font-size: 22px;
  padding-right: 0;
}

/* btn-animation  */
.btn-animation i {
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #a61d37;
  border-radius: 50%;
  text-align: center;
  line-height: 3.125rem;
  color: #ffffff;
  z-index: 9;
}

@media (max-width: 767px) {

  /* video-btn */
  .video-btn {
    width: 50px;
    height: 50px;
  }

  .video-btn i {
    font-size: 14px;
  }
}

/*****************************
  Category
*****************************/
.category {
  cursor: pointer;
}

.category .category-item {
  display: block;
}

.category .category-item .category-icon i {
  font-size: 50px;
  line-height: 50px;
  color: #1a2844;
}

.category .category-item h5 {
  font-weight: 500;
  font-size: 18px;
}

.category {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.category .category-item h5 {
  font-size: 18px;
}

.category:hover .category-icon i {
  color: #ffffff !important;
}

.category:hover .category-item h5 {
  color: #ffffff !important;
}

/* category-style-2 */
.category-style-2 {
  cursor: pointer;
}

.category-style-2:hover {
  background: #a61d37 !important;
  border-radius: 5px;
  color: #ffffff;
}

.category-style-2:hover .category-icon i {
  color: #ffffff !important;
}

.category-style-2:hover h5 {
  color: #ffffff !important;
}

/*****************************
  Client
*****************************/
.our-clients.owl-carousel {
  padding: 40px 50px;
  background-color: #a61d37;
}

.our-clients.owl-carousel .owl-item img {
  padding: 0 25px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.our-partners .item img {
  width: 90%;
  display: inherit;
  background: #f6f6f6;
  padding: 20px 20px;
  height: 70px;
}

/*****************************
  Countdown
*****************************/
.countdown-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.countdown-wrapper .countdown {
  padding: 25px 50px 25px 0px;
  width: 100%;
}

.countdown-wrapper .countdown span {
  font-size: 54px;
  line-height: 54px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #1a2844;
}

.countdown-wrapper .countdown p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
}

/*****************************
  Responsive
*****************************/
@media (max-width: 575px) {
  .countdown-wrapper {
    margin-bottom: 20px;
  }

  .countdown-wrapper .countdown {
    padding: 0px 10px;
  }

  .countdown-wrapper .countdown span {
    font-size: 32px;
    line-height: 54px;
  }

  .countdown-wrapper .countdown p {
    font-size: 14px;
  }
}

/*****************************
  Counter
*****************************/
.counter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.counter .counter-icon {
  margin-right: 30px;
}

.counter .counter-icon i {
  font-size: 60px;
  line-height: 60px;
  color: #a61d37;
}

.counter .counter-content .timer {
  position: relative;
  font-size: 44px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #1a2844;
  margin-bottom: 10px;
  line-height: 44px;
  display: inline-block;
}

.counter .counter-content .counter-icon-sign {
  font-size: 52px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #1a2844;
  margin-bottom: 10px;
  line-height: 52px;
}

.counter .counter-content label {
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 0;
}

.bg-primary-gradient .counter .counter-icon i {
  background: #1a2844;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1a2844;
}

.bg-primary-gradient .counter .counter-content .timer {
  color: #ffffff;
}

.bg-primary-gradient .counter .counter-content .counter-icon-sign {
  color: #ffffff;
}

.bg-primary-gradient .counter .counter-content label {
  color: #ffffff;
}

.bg-dark-gradient .counter .counter-icon i {
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ffffff;
}

.bg-dark-gradient .counter .counter-content .timer {
  color: #ffffff;
}

.bg-dark-gradient .counter .counter-content .counter-icon-sign {
  color: #ffffff;
}

.bg-dark-gradient .counter .counter-content label {
  color: #ffffff;
}

@media (max-width: 1199px) {
  .counter {
    margin-left: 0;
  }

  .counter .counter-icon {
    margin-right: 10px;
  }

  .counter .counter-content .time {
    font-size: 38px;
    line-height: 38px;
  }

  .counter .counter-content label {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .counter .counter-content .timer {
    font-size: 40px;
    line-height: 40px;
  }

  .counter .counter-content .counter-icon-sign {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 479px) {
  .counter .counter-content .timer {
    font-size: 20px;
    line-height: 18px;
  }

  .study-area {
    padding: 30px 0 30px 0 !important;
  }

  .news-section {
    padding: 30px 0 80px 0 !important;
  }

  .news-section .btn-primary {
    float: right;
  }

  .header .navbar-toggler {
    top: -70px !important;
  }

  .header-main {
    padding: 5px 0 !important;
  }

  .sticky-top .header-main {
    padding: 2px 0 5px !important;
  }
}

.main-banner .carousel-item {
  transform: none;
}

/*****************************
	Course
*****************************/
.course-item {
  position: relative;
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
}

.course-item:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.course-item .coures-img {
  position: relative;
}

.course-item .coures-img img {
  border-radius: 5px 5px 0 0;
}

.course-item .coures-img .course-tag {
  position: absolute;
  top: 10px;
  left: 0px;
}

.course-item .coures-img .course-tag a {
  padding: 4px 12px;
  background: #019440;
  border-radius: 3px;
  display: block;
  color: #ffffff;
  font-size: 16px;
}

.course-item .coures-img .course-tag a:hover {
  background: #ffffff;
  color: #a61d37;
}

.course-item .course-conten {
  padding: 20px;
  position: relative;
  border: 1px solid #f6f6f6;
  border-top: 0;
  background: #ffffff;
}

.course-item .course-author img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 10px;
}

.course-item .course-author span {
  font-weight: 500;
  color: #1a2844;
}

.course-item .course-meta ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-item .course-meta ul li {
  padding-right: 10px;
}

.course-item .course-meta ul li:last-child {
  padding-right: 0;
}

.course-item .course-meta ul .price {
  font-size: 16px;
  color: #a61d37;
  font-weight: 600;
}

.course-item .course-meta ul .price span {
  color: #c1c1c1;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: normal;
}

/* review */
.review .review-body .review-rating {
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 3;
}

.review .review-body .review-rating ul {
  padding-left: 0px;
}

.review .review-body .review-rating ul li {
  list-style: none;
  display: inline-block;
}

/* lecturer-schedule */
.lecturer-schedule ul li {
  width: 100px;
  display: inline-block;
  border-right: 1px solid #cdcdcd;
  margin-right: 15px;
  height: 58px;
}

.course-details-lecturer ul li .lecturer-img img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 20px;
}

.course-details-lecturer ul li .lecturer-content {
  border-right: 1px solid #cdcdcd;
  padding-right: 20px;
  margin-right: 10px;
  width: 26%;
}

.popular-courses .accordion .card {
  margin-bottom: 20px;
  border-radius: 5px;
}

.popular-courses .accordion .card .card-header {
  margin-bottom: 0;
}

.popular-courses .accordion .card .card-header .btn {
  padding: 15px 25px;
  color: #1a2844;
  font-size: 16px;
}

.popular-courses .accordion .card .card-body {
  border-top: 2px solid #a61d37;
  padding: 25px 25px;
}

.popular-courses .accordion .card .card-body a {
  display: block;
  margin-bottom: 5px;
}

.popular-courses .accordion .card .card-body p {
  margin-bottom: 0;
}

/* Course-said-bar */
.course-sidebar {
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 40px;
}

.course-sidebar .widget {
  margin-bottom: 50px;
}

.course-sidebar .widget .widget-title {
  margin-bottom: 30px;
}

.course-sidebar .widget .search {
  position: relative;
}

.course-sidebar .widget .search .form-control {
  background-color: #ffffff;
  color: #1a2844;
  border: 1px solid #a61d37;
}

.course-sidebar .widget .search i {
  position: absolute;
  right: 0;
  top: 0;
  background: #a61d37;
  color: #ffffff;
  padding: 17px 16px;
  cursor: pointer;
  border-radius: 5px;
  border-radius: 0px 5px 5px 0px;
}

.course-sidebar .widget .widget-course ul li {
  border-bottom: 1px solid rgba(205, 205, 205, 0.4);
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.course-sidebar .widget .widget-course ul li a {
  display: block;
  color: #1a2844;
}

.course-sidebar .widget .widget-course ul li a:hover {
  color: #a61d37;
}

.course-sidebar .widget .widget-course ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.course-sidebar .widget .widget-course ul i {
  right: 47px;
  position: absolute;
}

.course-sidebar .widget .recent-posts a {
  font-size: 13px;
  color: #1a2844;
}

.course-sidebar .widget .recent-posts a:hover {
  color: #a61d37;
}

.course-sidebar .widget .recent-posts .date {
  font-size: 11px;
}

.course-sidebar .widget .social ul {
  margin-bottom: 0;
}

.course-sidebar .widget .social ul li {
  display: inline-block;
  list-style-type: none;
  padding: 0 15px;
}

.course-sidebar .widget .social ul li a {
  font-size: 18px;
  color: #1a2844;
}

.course-sidebar .widget .social ul li a:hover {
  color: #a61d37;
}

.course-sidebar .widget .social ul li:last-child {
  padding-right: 0;
}

.course-sidebar .widget .social ul li:first-child {
  padding-left: 0;
}

.course-sidebar .widget:last-child {
  margin-bottom: 0;
}

.review .review-rating ul {
  padding-left: 0px;
}

.review .review-rating ul li {
  list-style: none;
  display: inline-block;
}

.widget .progress {
  height: 8px;
  margin-bottom: 15px;
}

.widget .progress .progress-bar {
  height: 8px;
}

.widget-categories ul li {
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.widget-categories ul li a {
  display: block;
  color: #1a2844;
}

.widget-categories ul li a:hover {
  color: #a61d37;
}

.widget-categories ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .course-sidebar {
    padding: 20px;
  }

  .course-sidebar .widget {
    margin-bottom: 30px;
  }

  .course-details-lecturer .lecturer-img {
    margin-bottom: 10px;
  }

  .course-details-lecturer ul li .lecturer-content {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .lecturer-schedule ul li {
    border-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 479px) {
  .course-item .course-conten {
    padding: 20px;
  }

  .popular-courses .accordion .card .card-header .btn {
    font-size: 14px;
  }
}

/*****************************
	events
*****************************/
.events .events-img img {
  border: 5px solid #a61d37;
  border-radius: 5px;
}

.events .events-content .events-tag a {
  padding: 3px 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  background: rgba(166, 29, 55, 0.1);
  color: #a61d37;
}

.events .events-content .events-tag a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.events .events-content .events-tag .badge-danger {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.events .events-content .events-tag .badge-success {
  background: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.events .events-content .events-tag .badge-warning {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.events .events-content .events-tag .badge-info {
  background: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

.events .events-content .events-tag .badge-dark {
  background: rgba(52, 58, 64, 0.1);
  color: #343a40;
}

.events .events-content .events-meta ul li {
  margin-right: 5px;
}

.events .events-content .events-meta ul li a {
  color: #666666;
  font-weight: 500;
}

.events .events-content .events-meta ul li a:hover {
  color: #a61d37;
}

.events .events-content .events-meta ul li i {
  color: #a61d37;
}

.event-list .event-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 220px;
  flex: 0 0 220px;
}

.event-list .event-content .event-meta ul li {
  margin-right: 5px;
}

.event-list .event-content .event-meta ul li a {
  color: #666666;
  font-weight: 500;
}

.event-list .event-content .event-meta ul li a:hover {
  color: #a61d37;
}

.event-list .event-content .event-meta ul li i {
  color: #a61d37;
}

/*****************************
  Feature Info
*****************************/
.feature-box {
  padding: 50px;
}

.feature-box .feature-icon {
  border-radius: 50%;
  text-align: center;
  width: 130px;
  height: 130px;
  line-height: 130px;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 130px;
  flex: 0 0 130px;
}

.feature-box .feature-icon i {
  font-size: 52px;
}

@media (max-width: 1199px) {
  .feature-box {
    padding: 30px;
  }

  .feature-box .feature-icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
  }

  .feature-box .feature-icon i {
    font-size: 30px;
  }
}

/*****************************
  form
*****************************/
.course-form {
  padding: 30px 50px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
}

.course-form .form-group {
  position: relative;
}

.course-form .form-group label {
  color: #1a2844;
  font-weight: 600;
}

.course-form .form-group .form-control {
  background: transparent;
  border: 1px solid #eeeeee;
  color: #b7b7b7;
}

.course-form .form-group i {
  position: absolute;
  right: 15px;
  top: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #a61d37;
}

.subscribe-form {
  position: relative;
}

.subscribe-form .form-control {
  padding: 10px 40px 10px 20px;
}

.subscribe-form i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .course-form {
    padding: 20px 20px;
  }
}

/*****************************
	Gallery
*****************************/
.gallery {
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.gallery .gallery-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
}

.gallery .gallery-content i {
  border-radius: 5px;
  color: #ffffff;
  font-size: 20px;
  background: #a61d37;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.gallery:hover .gallery-content {
  opacity: 1;
  visibility: visible;
}

.gallery:hover:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 40, 68, 0.5);
}

/*****************************
  List Style
*****************************/
.list-check {
  margin: 0;
  padding: 0;
}

.list-check li {
  position: relative;
  list-style-type: none;
  margin-bottom: 20px;
}

.list-check li i {
  background: #f4f4f5;
  color: #a61d37;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;
}

.list-check li:last-child {
  margin-bottom: 0;
}

/*****************************
	Owl Carousel
*****************************/

.main-banner .carousel-item {
  transform: none;
}

.owl-carousel .owl-item img {
  width: auto;
  display: inherit;
}

.owl-carousel .owl-nav {
  cursor: pointer;
  z-index: 999;
  width: 100%;
}

.owl-carousel .owl-nav button span {
  position: relative;
  top: -4px;
  width: 18px;
  height: 2px;
  display: inline-block;
  background: #a61d37;
  -webkit-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
}

.owl-carousel .owl-nav button span:before {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
}

.owl-carousel .owl-nav button span:after {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
}

.owl-carousel .owl-nav button span.prev-arrow {
  margin-right: 15px;
}

.owl-carousel .owl-nav button span.prev-arrow:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -4px;
  left: 0px;
  width: 10px;
}

.owl-carousel .owl-nav button span.prev-arrow:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
  bottom: -4px;
  left: 0px;
}

.owl-carousel .owl-nav button span.next-arrow {
  margin-left: 15px;
}

.owl-carousel .owl-nav button span.next-arrow:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -4px;
  right: 0px;
  width: 10px;
}

.owl-carousel .owl-nav button span.next-arrow:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 10px;
  bottom: -4px;
  right: 0px;
}

.owl-carousel .owl-nav button:hover span {
  width: 50px;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-indent: inherit;
  width: auto;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  left: 0px;
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 !important;
  display: inline-block;
  margin: 14px 2px 0;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-indent: inherit;
  width: auto;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  right: 0px;
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 !important;
  display: inline-block;
  margin: 14px 2px 0;
}

.owl-carousel .owl-dots .owl-dot span {
  background: #c1c1c1;
  width: 10px;
  min-height: 4px;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: #a61d37;
  width: 30px;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #a61d37;
  width: 30px;
}

.owl-carousel .owl-dot {
  display: inline-block;
  margin: 0px 4px 0;
}

.owl-dots-bottom-left {
  padding-bottom: 40px;
}

.owl-dots-bottom-left .owl-dots {
  bottom: 0px;
  position: absolute;
  left: 0px;
  width: auto;
}

.owl-dots-bottom-center {
  padding-bottom: 40px;
}

.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }

  .owl-carousel .owl-nav {
    display: none;
  }
}

/*****************************
	Portfolio
*****************************/
.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px;
}

.my-shuffle-container .grid-item {
  width: 33.33%;
  padding: 15px;
}

.portfolio-item img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.portfolio-item .portfolio-img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.portfolio-item .portfolio-img i {
  font-size: 20px;
}

.portfolio-item .portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 5px;
}

.portfolio-item .portfolio-overlay .portfolio-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  right: inherit;
}

.portfolio-item .portfolio-overlay .portfolio-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffffff;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border: 2px solid #ed1c24;
  border-radius: 5px;
  background: #ed1c24;
}

.portfolio-item .portfolio-info {
  margin-top: 25px;
  margin-bottom: 35px;
  position: relative;
}

.portfolio-item .portfolio-info .portfolio-category {
  margin-bottom: 5px;
  display: block;
}

.portfolio-item .portfolio-info .portfolio-category a {
  font-size: 14px;
  font-weight: 600;
  background: #a61d37;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.portfolio-item .portfolio-info .portfolio-title {
  color: #1a2844;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.portfolio-item .portfolio-info .portfolio-title:hover {
  color: #a61d37;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
  background: rgba(26, 40, 68, 0.5);
}

.portfolio-item.portfolio-item-02 {
  overflow: hidden;
}

.portfolio-item.portfolio-item-02 .portfolio-info {
  padding: 20px;
  width: 90%;
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 2;
  opacity: 0;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  background: #ffffff;
  margin-top: 0px;
  margin-bottom: 0px;
}

.portfolio-item.portfolio-item-02:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

.my-shuffle-container .portfolio-item .portfolio-info {
  margin-bottom: 0px;
}

.filters-group button {
  margin: 0 5px 10px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #222222;
  background: inherit;
  border: none;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.filters-group button:hover {
  background: #ed1c24;
  color: #ffffff;
}

.filters-group button.active {
  background: #ed1c24;
  color: #ece8e8;
}

.bg-dark .portfolio-item .portfolio-info .portfolio-title {
  color: #ffffff;
}

.bg-dark .portfolio-item .portfolio-info .portfolio-title:hover {
  color: #a61d37;
}

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.grid-style .grid-item {
  padding: 0 !important;
}

.my-shuffle-container.columns-1 .grid-item {
  width: 100%;
  padding: 15px;
}

.my-shuffle-container.columns-2 .grid-item {
  width: 50%;
  padding: 15px;
  float: left;
}

.my-shuffle-container.columns-3 .grid-item {
  width: 33.33333333%;
  padding: 15px;
}

.my-shuffle-container.columns-4 .grid-item {
  width: 25%;
  padding: 10px;
}

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 15px;
}

.my-shuffle-container.columns-6 .grid-item {
  width: 16.666666%;
  padding: 0px;
}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%;
}

@media (max-width: 991px) {
  .my-shuffle-container.columns-3 .grid-item {
    width: 50%;
  }
}

@media (max-width: 767px) {

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%;
  }

  .filters-group button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

@media (max-width: 575px) {

  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container .grid-item {
    width: 100%;
  }

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%;
  }
}

/*****************************
  Pricing Table
*****************************/
.pricing-tab {
  text-align: center;
}

.pricing-tab span {
  vertical-align: middle;
}

.pricing-tab .yearly-tab {
  font-weight: 700;
  font-size: 18px;
  color: #1a2844;
}

.pricing {
  padding: 50px 30px;
  text-align: center;
  -webkit-box-shadow: 0 1rem 3rem rgba(22, 36, 62, 0.175);
  box-shadow: 0 1rem 3rem rgba(22, 36, 62, 0.175);
  border-radius: 5px;
  border-bottom: 5px solid #a61d37;
}

.pricing .pricing-title {
  margin-bottom: 20px;
}

.pricing .pricing-price {
  display: inline-block;
  position: relative;
  color: #a61d37;
  font-weight: 600;
  margin-top: 30px;
}

.pricing .pricing-price sup {
  position: absolute;
  top: 15px;
  left: -20px;
  font-size: 24px;
  color: #1a2844;
}

.pricing .pricing-price strong {
  color: #1a2844;
  font-size: 60px;
  line-height: 60px;
  padding-right: 10px;
  font-weight: 500;
}

.pricing .change-pricing-price .yearly-price {
  display: block;
}

.pricing .pricing-list {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pricing .pricing-list li {
  padding: 6px 0;
  color: #1a2844;
  font-weight: 600;
}

.pricing .pricing-list li:last-child {
  padding-bottom: 0;
}

.pricing.active {
  background: #a61d37;
  color: #ffffff;
  border-top: 5px solid #a61d37;
  border-bottom: 5px solid #a61d37;
}

.pricing.active .pricing-list li {
  color: #ffffff;
}

.pricing.active .pricing-title {
  color: #ffffff;
}

.pricing.active .pricing-price {
  color: #ffffff;
}

.pricing.active sup {
  color: #ffffff;
}

.pricing.active strong {
  color: #a61d37;
}

/*****************************
	Select Dropdown
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0px;
}

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #1a2844;
  line-height: inherit;
}

.bigdrop {
  min-width: 196px !important;
}

.select2-results__options {
  padding: 8px 2px;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-results__option--highlighted {
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  padding-left: 10px;
  font-weight: 600;
}

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 1px;
  right: 0px;
  height: 21px;
  width: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  color: #a61d37;
}

.select2-container--default .select2-search--dropdown {
  padding: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #eeeeee;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f6f6f6;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(166, 29, 55, 0.1);
  color: #a61d37;
}

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9;
}

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #eeeeee;
  height: 50px;
  padding: 15px 20px;
  border-radius: 3px;
}

.select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 16px;
  right: 20px;
}

.select2-container--default .select2-results>.select2-results__options {
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #b7b7b7;
}

.select2-container--open .select2-dropdown {
  background: #f6f6f6;
  padding: 7px;
  border: 1px solid #eeeeee;
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid #eeeeee;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
}

/*****************************
	Skill
*****************************/
.skill {
  position: relative;
  width: 100%;
  height: 7px;
  margin: 70px 0 20px 0;
  background-color: #f4f4f5;
  border-radius: 5px;
}

.skill .skill-bar {
  position: relative;
  height: 100%;
  border-radius: 5px;
  padding: 0px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(30%, #a61d37),
      to(#fa3a72));
  background: linear-gradient(to right, #a61d37 30%, #fa3a72 100%);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 0.9s ease;
  transition: width 0.9s ease;
}

.skill .skill-bar .skill-title {
  color: #1a2844;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  margin-left: 0;
  margin-top: -28px;
  text-align: left;
}

.skill .skill-bar .skill-title i {
  margin-right: 8px;
}

.skill .skill-bar .progress-number {
  color: #1a2844;
  float: right;
  margin-top: -18px;
  position: absolute;
  right: 14px;
  top: -10px;
  font-size: 15px;
  font-weight: 600;
}

.skill .skill-bar .progress-type {
  color: #1a2844;
  float: right;
  margin-top: -18px;
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 15px;
  font-weight: 600;
}

.skill .skill-bar:before {
  content: "";
  position: absolute;
  right: 0;
  background: #a61d37;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

/*****************************
	Social Icon
*****************************/
.social-icon ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

.social-icon ul li {
  list-style-type: none;
  margin-right: 20px;
}

.social-icon ul li a {
  font-size: 14px;
  color: #ffffff;
  display: block;
}

.social-icon ul li a:hover {
  color: #b3e029;
}

.social-icon ul li:last-child {
  margin-right: 0;
}

.social-icon.social-icon-style-02 ul {
  padding-left: 0px;
}

.social-icon.social-icon-style-02 ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}

.social-icon.social-icon-style-02 ul li a {
  background: #ffffff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: #1a2844;
}

.social-icon.social-icon-style-02 ul li a:hover {
  color: #ffffff;
  background: #ed1c24;
}

/*****************************
	Swiper Slider
*****************************/
.swiper-button-prev {
  background-image: none;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #1a2844;
  height: 40px;
  border-radius: 5px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  left: 3%;
}

.swiper-button-next {
  background-image: none;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #1a2844;
  height: 40px;
  border-radius: 5px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  right: 3%;
}

@media (max-width: 767px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

/*****************************
 About

*****************************/

@media (max-width: 479px) {
  .about-area {
    padding: 30px 0 0 0 !important;
  }

  .about-area .about-img {
    margin-top: 20px;
  }

  .footer-section {
    padding: 0px !important;
  }

  .footer-bottom {
    padding: 20px 0 20px 0;
  }
}

/*****************************
  Team

*****************************/

@media (max-width: 479px) {
  .team {
    padding: 0 15px;
  }
}

/*****************************
  Tab
*****************************/
.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  background: #a61d37;
  color: #ffffff;
  border-radius: 0;
  border: none;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: 600;
  width: 25%;
  text-align: center;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  color: #a61d37;
  background-color: #ffffff;
}

/*****************************
  responsive
*****************************/
@media (max-width: 575px) {
  .nav-tabs .nav-link {
    padding: 15px 30px;
    width: 100%;
    margin-bottom: 10px;
  }
}

/*****************************
	Team
*****************************/
.team {
  text-align: center;
  position: relative;
  text-align: center;
  position: relative;
  padding: 10px;
}

.team img {
  border-radius: 5px;
}

.team:hover .team-img {
  z-index: 9;
  position: relative;
}

.team:hover .team-info {
  z-index: 9;
  position: relative;
}

.team:hover .team-info ul {
  opacity: 1;
  top: -15px;
}

.team:hover .team-bg {
  opacity: 1;
  z-index: -99;
}

.team .team-info {
  padding: 35px 0px 10px 0px;
  text-align: center;
  margin: -30px 25px 0;
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  /*box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
    background-color: #ffffff;*/
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background-color: #f6f1f1;
  border-radius: 5px;
  position: relative;
  z-index: 9;
  font-weight: 700;
  margin-bottom: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: block;
  color: #1a2844;
  font-size: 18px;
  line-height: 1.2;
}

.team .team-info:hover {
  color: #1a2844;
}

.team .team-info .team-name {
  color: #1a2844;
  font-size: 22px;
  font-weight: 500;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.team .team-info .team-name:hover {
  color: #a61d37;
}

.team .team-info p {
  font-size: 14px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  margin-top: 12px;
}

.team-leader a {
  color: #ed1c24;
}

.team .team-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.team .team-info ul li a {
  display: block;
  margin: 0 5px;
  color: #ffffff;
  background: #1a2844;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}

.team .team-info ul li a:hover {
  color: #a61d37;
  background: #ffffff;
}

.team .team-info ul li a i {
  font-size: 14px;
}

.team:hover .team-info {
  background-color: #ed1c24;
  margin-top: -45px;
}

.team:hover .team-info .team-name {
  color: #ffffff;
}

.team:hover .team-info .team-leader a {
  color: #ffffff;
}

.team:hover .team-info p {
  color: #ffffff;
}

/** team-02 **/
.team-02 {
  position: relative;
  overflow: hidden;
}

.team-02 .team-title {
  display: block;
  color: #ffffff;
  font-size: 17px;
}

.team-02 .team-title:hover {
  color: #fff;
}

.team-02 .team-img {
  background-size: cover;
  min-height: 249px;
  border-radius: 5px;
  background-position: center center;
}

.team-02 .team-info {
  padding: 20px;
  width: 90%;
  position: absolute;
  left: 50%;
  bottom: -90px;
  z-index: 2;
  opacity: 0;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  background: #ed1c24;
}

.team-02 .team-info p {
  max-height: 66px;
  overflow: auto;
}

.team-02 .team-info .team-social ul {
  padding-left: 0px;
}

.team-02 .team-info .team-social ul li {
  display: inline-block;
  list-style: none;
  margin-right: 15px;
}

.team-02 .team-info .team-social ul li i {
  color: #ffffff;
}

.team-02 .team-info {
  opacity: 1;
  bottom: 20px;
}

/* team-single */
.team-single .social {
  margin-top: 40px;
}

.team-single .social ul li {
  list-style: none;
  display: inline-block;
}

.team-single .social ul li a {
  background: #a61d37;
  color: #ffffff;
  padding: 5px 10px 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
}

.sin-pag-img img {
  height: 50px;
}

.list ul li {
  margin-bottom: 15px;
  list-style: none;
}

.list ul li:last-child {
  margin-bottom: 0;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 !important;
  border: none;
}

/*************************
  Responsive
*************************/
@media (max-width: 991px) {
  .team .team-info {
    margin: -30px 15px 0;
  }
}

/*****************************
	Testimonial
*****************************/
.testimonial-item {
  position: relative;
}

.testimonial-item .testimonial-quote {
  margin-bottom: 20px;
}

.testimonial-item .testimonial-quote i {
  font-size: 28px;
  display: inline-block;
  line-height: 28px;
  background: #ed1c24;
  border-radius: 50%;
  padding: 15px;
}

.testimonial-item .testimonial-content p {
  font-size: 16px;
  color: #1a2844;
  margin-bottom: 25px;
}

.testimonial-item .testimonial-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.testimonial-item .testimonial-author .testimonial-avatar {
  margin-right: 15px;
  margin-bottom: 15px;
}

.testimonial-item .testimonial-author .testimonial-avatar img {
  border-radius: 50%;
}

.testimonial-item .testimonial-author .testimonial-name span {
  color: #ed1c24;
  font-weight: 500;
}

.testimonial-name {
  text-align: center;
}

/* testimonial center */
.testimonial-center .testimonial-item {
  text-align: center;
  padding: 0 15%;
}

.testimonial-center .testimonial-item .testimonial-author {
  margin-bottom: 0;
  display: inline-block;
}

/*****************************
  responsive
*****************************/
@media (max-width: 992px) {
  .testimonial-center .testimonial-item {
    padding: 0 5%;
  }
}

/*****************************
  Header
*****************************/
.mainheader {
  font-size: 16px;
  color: red;
  padding-top: 5px;
  font-family: sans-serif;
}

.testimonial-avatar {
  /* margin-left: 517px; */
}

.header {
  position: relative;
  z-index: 99;
}

.header .navbar-brand img {
  /*height: 50px;*/
  height: 55px;
}

.header .header-contact-info i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #c1c1c1;
  border-radius: 50px;
  color: #1a2844;
  margin-right: 10px;
}

.header .navbar {
  position: relative;
  width: 100%;
  z-index: 9;
  padding: 0px 0;
}

.header .navbar .navbar-brand {
  padding: 20px 0px;
  margin-right: 0;
  color: #ffffff;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
}

.header .navbar .navbar-brand img {
  height: 50px;
}

.header .navbar .navbar-toggler-icon {
  position: relative;
  width: 30px;
  height: 2px;
  background: #ed1c24;
}

.header .navbar .navbar-toggler-icon:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: #ed1c24;
  left: 0;
  top: -10px;
}

.header .navbar .navbar-toggler-icon:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: #ed1c24;
  left: 0;
  bottom: -10px;
}

.header .navbar .navbar-nav .nav-item .nav-link {
  font-weight: 500;
  font-size: 17px;
  /* padding: 18px 10px; */
  color: #1a2844;
  text-transform: capitalize;
}

.header .navbar .navbar-nav .nav-item .nav-link i {
  padding-left: 5px;
  font-size: 12px;
}

.header .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #ed1c24;
}

.header .navbar .dropdown .dropdown-menu {
  /*padding: 20px 30px;
      z-index: 9999;
      width: 250px;*/
  padding: 20px 20px;
  z-index: 9999;
  width: 260px;
}

.header .navbar .dropdown .dropdown-menu li a {
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px 10px 0;
  border-radius: 3px;
  min-width: 180px;
  white-space: normal;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .navbar .dropdown .dropdown-menu li a:hover {
  color: #ed1c24;
  padding-left: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .navbar .dropdown .dropdown-menu li a:before {
  position: absolute;
  content: "";
  background: #ed1c24;
  height: 3px;
  width: 0;
  left: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header .navbar .dropdown .dropdown-menu li a:hover:before {
  width: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .dropdown-toggle:after {
  content: none;
}

/*.sticky-top .header-main {
  padding: 10px 0 !important; }*/

.sticky-top.header .navbar-brand img {
  height: 50px;
}

.sticky-top.header .navbar .navbar-nav .nav-item .nav-link {
  padding: 10px 12px;
}

.navbar .dropdown>.dropdown-menu li.active>a {
  color: #a61d37;
  padding-left: 25px;
}

.navbar .navbar-nav .dropdown-menu li.active>a:before {
  width: 15px;
}

/* header style 02 */
.header.header-style-02.sticky-top .header-main {
  display: none;
}

.header.header.header-style-02.sticky-top .navbar .navbar-brand {
  padding: 10px 0px;
}

.header.header.header-style-02.sticky-top .navbar-nav .nav-item.active .nav-link {
  color: #ffffff;
}

.header.header.header-style-02.sticky-top .navbar .navbar-nav .nav-item:hover .nav-link {
  color: #ffffff;
}

.header.header-style-02 .navbar .navbar-nav .nav-item:hover .nav-link {
  color: #ffffff;
  background: #1a2844;
}

.header.header-style-02 .navbar .navbar-nav .nav-item.active .nav-link {
  color: #bee934;
  /*background: #1a2844;*/
}

.header.header-style-02 .navbar-brand.logo-sticky {
  display: none;
}

.header.header-style-02.sticky-top .navbar .navbar-brand {
  display: block;
}

.header.header-style-02 .navbar .dropdown .dropdown-menu {
  top: 50px;
}

.header.header-style-02.sticky-top .navbar-collapse {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* header-transparent */
.header.header-transparent {
  position: absolute;
  background: transparent !important;
  width: 100%;
  z-index: 99;
}

.header.header-transparent .navbar .navbar-brand {
  margin-right: 40px;
  padding: 20px 0;
}

.header.header-transparent .navbar .navbar-brand img {
  height: 40px;
}

.header.header-transparent .navbar .navbar-nav .nav-link {
  color: #ffffff;
}

.header-transparent .navbar .navbar-nav .nav-item.active .nav-link {
  background: inherit;
  color: #a61d37;
}

.header.header-transparent.header-sticky.sticky-top .logo {
  display: none;
}

.header.header-transparent.header-sticky.sticky-top .sticky-logo {
  display: block;
}

.header.header-transparent.header-sticky .sticky-logo {
  display: none;
}

.header .navbar .navbar-nav .nav-item.active .nav-link {
  background: inherit;
  color: #049443;
}

.header .navbar-toggler {
  border: none;
  position: absolute;
  right: 0px;
  padding: 0;
  top: -64px;
}

/* Search */
.search-icon li {
  list-style: none;
}

.serach .input-group i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.search {
  vertical-align: middle;
  display: table;
  height: 100%;
}

.search .search-btn {
  display: block;
  height: 22px;
  line-height: 22px;
  position: relative;
  text-indent: -9999px;
  width: 14px;
  color: #a61d37;
  -webkit-transition: color 300ms ease 0s;
  transition: color 300ms ease 0s;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.testimonial-avatar {
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}

.search .search-btn:before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  left: 0;
  position: absolute;
  text-indent: 0;
  top: 0;
}

.search.search-open .search-btn:before {
  content: "\f00d";
}

.search-box {
  display: block;
  margin: 0;
  opacity: 0 !important;
  position: absolute;
  right: 0;
  top: 76px;
  -webkit-transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
  transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
  visibility: hidden;
  width: 327px;
  z-index: 888;
  border-radius: 3px;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.search-box.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.search.search-open .search-box {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-box-shadow: 0 1rem 3rem rgba(22, 36, 62, 0.175);
  box-shadow: 0 1rem 3rem rgba(22, 36, 62, 0.175);
}

.search .search-box a {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.search .search-box .form-control {
  height: 58px;
  padding: 10px 50px 10px 20px;
  background: #ffffff;
}

.header.bg-light .navbar .navbar-nav .nav-link {
  color: #1a2844;
}

.header.bg-primary .search-btn {
  color: #ffffff;
}

.header.bg-primary .navbar .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.header.bg-primary .navbar .navbar-nav .nav-item.active .nav-link,
.header.bg-primary .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #ffffff;
}

/* Sticky */
@-webkit-keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.header.sticky-top {
  position: fixed;
  left: 0;
  width: 100%;
  background: #ffffff !important;
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 999;
  -webkit-animation: stickySlideDown 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: stickySlideDown 1s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.header.sticky-top .topbar {
  display: none !important;
}

.header.sticky-top .navbar .navbar-brand {
  padding: 15px 0px;
}

.header.sticky-top .navbar .navbar-nav .nav-item .nav-link {
  color: #1a2844;
}

.header.sticky-top .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #1a2844;
}

.header.sticky-top .navbar-nav .nav-item.active .nav-link {
  color: #019440;
}

.header.sticky-top .navbar-nav .nav-item.active .nav-link:hover {
  color: #a61d37;
}

.header.sticky-top .search-btn {
  color: #a61d37 !important;
}

.header.bg-primary.sticky-top .btn {
  background: #a61d37;
  color: #ffffff;
  border-color: #a61d37;
}

/* Header 02 */
.header.sticky-top .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #ed1c24;
}

.header.sticky-top.bg-primary .navbar .navbar-nav .nav-item.active .nav-link,
.header.sticky-top.bg-primary .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #a61d37;
}

/*****************************
   header Responsive
*****************************/
@media (min-width: 992px) {

  /* dropdowns */
  .dropdown {
    display: inline-block;
  }

  .dropdown .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    left: 100%;
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, transform;
    transition-property: opacity, visibility, transform, -webkit-transform;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important;
  }

  .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1199px) {
  .btn {
    padding: 12px 22px;
  }

  .header .navbar .navbar-nav .nav-item .nav-link {
    /* padding: 10px 16px; */
    font-size: 14px;
  }

  .header.sticky-top .navbar-brand img {
    height: 55px !important;
  }

  .search-box {
    top: 66px;
  }
}

@media (max-width: 991px) {
  .header {
    -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
    box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  }

  .header.header-style-02 .navbar-brand.logo-sticky {
    display: block;
  }

  .header.header-style-02 .navbar-brand {
    display: none;
  }

  .header-style-02 .navbar-toggler {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .header.header-transparent {
    position: relative;
    background: #ffffff !important;
  }

  .header.header-transparent.bg-primary {
    position: relative;
    background: #1a2844 !important;
  }

  .header.header-transparent.sticky-top {
    position: fixed;
  }

  .navbar-toggler {
    border: none;
    position: absolute;
    height: 75px;
    right: 0px;
    padding: 0;
    top: -64px;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    left: 0;
    margin-left: 0px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .navbar .navbar-nav .dropdown-menu li.active>a {
    padding-left: 25px;
  }

  .header .navbar .dropdown .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f4f4f5;
    padding: 14px 30px;
    font-size: 13px;
    width: 100%;
  }

  .navbar .navbar-nav .nav-link i {
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .header.header-transparent .navbar .navbar-nav .nav-link {
    color: #1a2844;
  }

  .header.header-transparent.header-sticky .sticky-logo {
    display: inline-block;
  }

  .header.header-transparent .logo {
    display: none;
  }

  .header.bg-primary .navbar .navbar-nav .nav-item .nav-link {
    color: #1a2844;
  }

  .header.bg-primary .navbar .navbar-nav .nav-item.active .nav-link,
  .header.bg-primary .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #a61d37;
  }
}

@media (max-width: 767px) {

  .header .navbar .dropdown .dropdown-menu li a,
  .header .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }

  .header .navbar-brand img {
    height: 55px !important;
  }
}

/*****************************
  Layout
*****************************/
/* Section Title */
.section-title {
  margin-bottom: 50px;
}

.section-title .title {
  margin-bottom: 20px;
}

.section-title p {
  margin-bottom: 30px;
}

/* Get touch */
.get-touch {
  background: #f6f6f6;
  padding: 30px;
  text-align: center;
  position: relative;
  height: 350px;
  overflow: hidden;
}

.get-touch svg {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0;
  height: auto;
}

/* Text bg image */
.clipped-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url(../images/bg/06.jpg);
  font-weight: 900;
  color: #a61d37;
}

/* Sticky Image */
.sticky-img {
  position: fixed;
  top: 160px;
  width: 50%;
}

.sticky-img.right-split {
  right: 0;
}

.sticky-img.left-split {
  left: 0;
}

/* Subscribe */
.subscribe {
  position: relative;
}

.subscribe .form-group .form-control {
  padding-right: 75px;
}

.subscribe button {
  position: absolute;
  right: 10px;
  top: 50%;
  padding: 23px 10px;
  background: none;
  border: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Frame Layout */
.frame-layout {
  padding: 20px;
}

/* Sticky column */
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
}

/* Back To Top */
.back-to-top {
  background: #ed1c24;
  border-radius: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: width 0.5s ease-in-out, margin 0.5s ease-in-out,
    border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out,
    border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 9;
}

.back-to-top:hover,
.back-to-top.auto {
  color: rgba(255, 255, 255, 0);
  -webkit-transition: width 0.5s ease-in-out, margin 0.5s ease-in-out,
    border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out,
    border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
}

.back-to-top:hover:before,
.back-to-top.auto:before {
  -webkit-animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.back-to-top:hover:after,
.back-to-top.auto:after {
  -webkit-animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.back-to-top:before {
  position: absolute;
  display: inline-block;
  content: "";
  background: #ffffff;
  width: 3px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -2px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.visually-hidden {
  display: none;
}

.sticky-whatsapp {
  position: fixed;
  right: 25px;
  bottom: 20px;
  margin-bottom: 60px;
}

.back-to-top:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  color: #ffffff;
  border-top: 3px solid;
  border-left: 3px solid;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -7px;
  -webkit-transform: translateY(50px) rotateZ(45deg);
  transform: translateY(50px) rotateZ(45deg);
}

@-webkit-keyframes tipUp {
  0% {
    -webkit-transform: translateY(50px) rotateZ(45deg);
    transform: translateY(50px) rotateZ(45deg);
  }

  100% {
    -webkit-transform: translateY(-70px) rotateZ(45deg);
    transform: translateY(-70px) rotateZ(45deg);
  }
}

@keyframes tipUp {
  0% {
    -webkit-transform: translateY(50px) rotateZ(45deg);
    transform: translateY(50px) rotateZ(45deg);
  }

  100% {
    -webkit-transform: translateY(-70px) rotateZ(45deg);
    transform: translateY(-70px) rotateZ(45deg);
  }
}

@-webkit-keyframes lineUp {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}

@keyframes lineUp {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}

.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  display: block;
  width: 100%;
}

.social-bg-hover {
  position: relative;
  color: #ffffff;
}

.social-bg-hover:before {
  content: "";
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.social-bg-hover:hover {
  color: #ffffff;
}

.social-bg-hover:hover:before {
  background-color: rgba(0, 0, 0, 0.1);
}

.social-bg-hover span {
  position: relative;
}

.facebook-bg {
  background-color: #445c8e;
}

.twitter-bg {
  background-color: #43afe9;
}

.google-bg {
  background-color: #dc0510;
}

.instagram-bg {
  background-color: #dd2a7b;
}

.linkedin-bg {
  background-color: #007eb3;
}

/* error */
.error-text {
  font-size: 268px;
  line-height: 266px;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
  color: #1a2844;
}

.counter-content {
  color: white;
  font-size: 40px;
}

.owl-carousel button.owl-dot {
  width: 15px;
  height: 15px;
  border: 2px solid #ed1c24;
  background: inherit !important;
  border-radius: 50%;
  margin-right: 10px;
  opacity: 0.5;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
  margin-left: 10px;
}

.owl-carousel button.owl-dot.active {
  background: #ed1c24 !important;
  opacity: 1;
}

.registration img {
  position: absolute;
  left: -100px;
  top: 7%;
  bottom: 0;
  border: 7px solid #a61d37;
}

.form-group .form-icon {
  border: 2px solid #f4f4f5;
  padding: 5px;
  border-radius: 5px;
}

.form-group .form-icon i {
  color: #ffffff;
  background: #a61d37;
  height: 48px;
  padding: 15px;
  line-height: 20px;
  border-radius: 3px 0px 0px 3px;
}

.form-group .form-icon .form-control {
  border-radius: 0px 3px 3px 0px;
  background: inherit;
}

.icon-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  text-align: center;
  background: #a61d37;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
}

.icon-btn:hover {
  color: #ffffff;
}

.btn-animation:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #a61d37;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.btn-animation.icon-btn-lg:before {
  width: 80px;
  height: 80px;
}

/* keyframes */
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes sonarWave {
  from {
    opacity: 0.4;
  }

  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }

  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

.login {
  border-top: 7px solid #a61d37;
  border-radius: 5px;
}

.inner-banner {
  padding: 100px 0;
}

.course-archive .navbar-form a {
  background: #a61d37;
  height: 48px;
  line-height: 48px;
  padding: 0px 15px;
  border-radius: 0px 5px 5px 0px !important;
}

/* pagination */
.pagination {
  border-radius: 5px;
}

.pagination .page-item .page-link {
  padding: 14px 25px;
  color: #1a2844;
  border-color: rgba(26, 40, 68, 0.1);
  border-radius: 0px;
}

.pagination .page-item .page-link:hover {
  background: #a61d37;
  color: #ffffff;
}

.pagination .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination .page-item.active .page-link {
  background: #a61d37;
  border-color: #a61d37;
  color: #ffffff;
}

/* banner */
.swiper-button-prev {
  background-image: none;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #1a2844;
  height: 40px;
  border-radius: 5px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  left: 3%;
}

.swiper-button-next {
  background-image: none;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #1a2844;
  height: 40px;
  border-radius: 5px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  right: 3%;
}

/* Navigation */
.navigation .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navigation .nav-links .nav-previous {
  width: 47%;
  border: 1px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}

.navigation .nav-links .nav-previous a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1a2844;
}

.navigation .nav-links .nav-previous .pagi-text {
  padding: 12px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation .nav-links .nav-previous:hover {
  background: #f8f9fa;
}

.navigation .nav-links .nav-previous:hover .pagi-text {
  background: #a61d37;
  color: #ffffff;
  border-radius: 3px 0 0 3px;
}

.navigation .nav-links .nav-previous:hover .nav-title {
  color: #a61d37;
}

.navigation .nav-links .nav-previous .nav-title {
  padding: 12px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation .nav-links .nav-previous .pagi-text {
  border-right: 1px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.navigation .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navigation .nav-links .nav-next {
  width: 47%;
  border: 1px solid #f6f6f6;
  text-align: right;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}

.navigation .nav-links .nav-next a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1a2844;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navigation .nav-links .nav-next .pagi-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 25px;
  border-left: 1px solid #f6f6f6;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation .nav-links .nav-next:hover {
  background: #f8f9fa;
}

.navigation .nav-links .nav-next:hover .pagi-text {
  background: #a61d37;
  color: #ffffff;
  border-radius: 0 3px 3px 0;
}

.navigation .nav-links .nav-next:hover .nav-title {
  color: #a61d37;
}

.navigation .nav-links .nav-next .nav-title {
  padding: 12px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* table */
.table {
  /*-webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
          box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  border: 1px solid #eeeeee;*/
}

/* blockquote */
.blockquote-quote {
  position: relative;
  font-size: 18px;
  border-left: 0 solid #a61d37;
  padding-left: 30px;
  font-style: italic;
  display: block;
  z-index: 1;
}

.blockquote-quote i {
  position: absolute;
  top: -20px;
  font-size: 60px;
  line-height: 60px;
  color: rgba(166, 29, 55, 0.2);
}

.blockquote .blockquote-info {
  padding: 25px 20px;
  border-left: 5px solid rgba(166, 29, 55, 0.7);
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  border-radius: 5px;
}

.blockquote .blockquote-content {
  font-size: 18px;
}

.map iframe {
  border: 0;
}

/*****************************
  Responsive
*****************************/
@media (max-width: 991px) {
  .imageblock-section-img {
    position: relative;
    height: 400px;
  }
}

@media (max-width: 767px) {

  /* Section title */
  .section-title {
    margin-bottom: 30px;
  }

  .section-title p {
    margin-bottom: 20px;
  }

  .get-touch {
    margin-top: 50px;
  }

  /* Sticky Image */
  .sticky-img.right-split,
  .sticky-img.left-split {
    position: inherit;
    top: 0;
    width: 90%;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 30px;
  }

  /* error */
  .error-text {
    font-size: 210px;
    line-height: 220px;
  }

  /* banner */
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
  .get-touch svg {
    bottom: -50px;
  }
}

@media (max-width: 991px) {
  .inner-banner {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .section-title {
    margin-bottom: 30px;
  }

  .inner-banner {
    padding: 70px 0;
  }
}

@media (max-width: 575px) {

  /* error */
  .error-text {
    font-size: 120px;
    line-height: 130px;
  }

  .pagination .page-item .page-link {
    padding: 10px 16px;
  }

  /* table */
  .table {
    width: 500px;
  }
}

/*****************************
  Footer
*****************************/
.footer .footer-logo {
  width: auto;
  height: 100px; /* Default size for larger screens */
  /* display: block;
  margin: 0 auto; */
}

/* For tablets and smaller devices */
@media screen and (max-width: 768px) {
  .footer .footer-logo {
    height: 80px; /* Slightly smaller on tablets */
    margin-top: 20px;
  }
}

/* For mobile devices */
@media screen and (max-width: 480px) {
  .footer .footer-logo {
    height: 60px; /* Smaller size on mobile */
    margin-top: 20px;
  }
}

/* For very small screens */
@media screen and (max-width: 320px) {
  .footer .footer-logo {
    height: 50px; /* Further reduced for very small mobile screens */
    margin-top: 20px;
  }
}


.footer .contact-item {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.footer .contact-item i {
  color: #a61d37;
  font-size: 22px;
  width: 20px;
}

.footer .footer-link ul li {
  margin-bottom: 10px;
}

.footer .footer-link ul li:last-child {
  margin-bottom: 0;
}

/*****************************
  		Blog
*****************************/
.blog {
  position: relative;
  overflow: hidden;
}

.blog .blog-content {
  border-radius: 0px;
}

.blog .blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.blog .blog-meta .blog-date {
  position: relative;
  padding-right: 16px;
}

.blog .blog-meta .blog-date:before {
  position: absolute;
  right: 0;
  width: 2px;
  height: 15px;
  background: #666666;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.blog .blog-meta a {
  color: #666666;
  font-weight: 500;
}

.blog .blog-meta a:hover {
  color: #a61d37;
}

.blog .blog-meta ul li {
  margin-right: 10px;
  border-right: 2px solid #d3cfcf;
  padding-right: 8px;
}

.blog .blog-meta ul li:last-child {
  border: 0px;
}

.blog .blog-meta ul li i {
  color: #ed1c24;
}

.blog .blog-title {
  line-height: 28px;
  color: #ed1c24;
  margin-bottom: 0;
}

.blog .blog-image {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}

.blog .blog-image .blog-tag {
  position: absolute;
  top: 15px;
  left: 15px;
}

.blog .blog-image .blog-tag a {
  padding: 6px 10px;
  border-radius: 5px;
  display: block;
}

.blog-post .blog-date {
  padding: 30px;
}

.blog-post .blog-date h3 {
  font-size: 36px;
  color: #ed1c24;
}

.blog-post .blog-date span {
  color: #ed1c24;
  font-size: 16px;
  font-weight: 500;
}

.blog-post .blog-contant {
  padding: 15px;
  padding-left: 0;
}

.blog-post .blog-contant .blog-meta ul li {
  margin-right: 10px;
}

.blog-post .blog-contant .blog-meta ul li a {
  color: #666666;
  font-weight: 500;
}

.blog-post .blog-contant .blog-meta ul li a:hover {
  color: #a61d37;
}

/* blog-sidebar */
.blog-sidebar {
  -webkit-box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  box-shadow: 0px 4px 20px -3px rgba(22, 36, 62, 0.15);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 40px;
}

.blog-sidebar .widget {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
}

.blog-sidebar .widget .widget-title {
  margin-bottom: 20px;
}

.blog-sidebar .widget .search {
  position: relative;
  width: 100%;
}

.blog-sidebar .widget .search .form-control {
  background-color: #ffffff;
  color: #1a2844;
  border: 1px solid #a61d37;
  border-radius: 5px !important;
}

.blog-sidebar .widget .search i {
  position: absolute;
  right: 0;
  top: 0;
  background: #a61d37;
  color: #ffffff;
  padding: 17px 16px;
  cursor: pointer;
  border-radius: 5px;
  border-radius: 0px 5px 5px 0px;
}

.blog-sidebar .widget .widget-categories ul li {
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.blog-sidebar .widget .widget-categories ul li a {
  display: block;
  color: #1a2844;
}

.blog-sidebar .widget .widget-categories ul li a:hover {
  color: #a61d37;
}

.blog-sidebar .widget .widget-categories ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.blog-sidebar .widget .recent-posts a {
  font-size: 13px;
  color: #1a2844;
}

.blog-sidebar .widget .recent-posts a:hover {
  color: #a61d37;
}

.blog-sidebar .widget .recent-posts .date {
  font-size: 11px;
}

.blog-sidebar .widget .social ul {
  margin-bottom: 0;
}

.blog-sidebar .widget .social ul li {
  display: inline-block;
  list-style-type: none;
  padding: 0 15px;
}

.blog-sidebar .widget .social ul li a {
  font-size: 18px;
  color: #1a2844;
}

.blog-sidebar .widget .social ul li a:hover {
  color: #a61d37;
}

.blog-sidebar .widget .social ul li:last-child {
  padding-right: 0;
}

.blog-sidebar .widget .social ul li:first-child {
  padding-left: 0;
}

.blog-sidebar .widget:last-child {
  margin-bottom: 0;
}

/*****************************
  responsive
*****************************/
@media (max-width: 1199px) {
  .blog-sidebar {
    padding: 25px;
  }
}

@media (max-width: 767px) {
  .blog-post .blog-contant .blog-meta ul li {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

/*************************
  Display Responsive
*************************/
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .container {
    max-width: 96%;
  }
}

@media (min-width: 1200px) {

  /* container */
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1500px) {

  /* container */
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 991px) {

  /* Page section margin padding */
  .space-ptb {
    padding: 70px 0;
  }

  .space-pt {
    padding-top: 70px;
  }

  .space-pb {
    padding-bottom: 70px;
  }

  .space-sm-ptb {
    padding: 50px 0;
  }

  .space-sm-pt {
    padding-top: 50px;
  }

  .space-sm-pb {
    padding-bottom: 50px;
  }

  .space-lg-mt {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {

  /* container */
  .container {
    max-width: 100%;
  }

  /* Page section margin padding */
  .space-ptb {
    padding: 50px 0;
  }

  .space-pt {
    padding-top: 50px;
  }

  .space-pb {
    padding-bottom: 50px;
  }

  .space-sm-ptb {
    padding: 30px 0;
  }

  .space-sm-pt {
    padding-top: 30px;
  }

  .space-sm-pb {
    padding-bottom: 30px;
  }

  .space-lg-mt {
    margin-top: 70px;
  }
}

/*# sourceMappingURL=../css/style.css.map */

.span-title {
  color: red;
}

.about-section {
  background-image: url("../images/about/about-bg.jpg");
  background-repeat: no-repeat;
}

.about-section p {
  /*color:#fff;*/
}

.row {
  font-size: 19px;
  font-weight: 400;
}

.bottom-bar {
  width: 100%;
  height: 5px;
  background: #ed1c24;
  /*position: absolute;*/
  left: 0;
  bottom: -5px;
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.topbar {
  background: #ed1c24 !important;
}

.topbar a {
  font-size: 15px;
}

/* ================= slider ================= */

/*.study-area {
    background-image: url(../images/about/about-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    z-index: 1;
}
.study-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--optional-color);
    opacity: 0.9;
    z-index: -1;
}
.study-area .section-title {
    margin-left: 0;
    text-align: left;
}
.section-title {
    max-width: 750px;
    margin: -8px auto 50px;
    text-align: center;
    position: relative;
}
.section-title.white-title h2 {
    color: var(--white-color);
}
.section-title h2 {
    font-size: 45px;
    margin-bottom: 15px;
    position: relative;
}
.study-area .section-title p {
    margin-left: 0;
}
.study-area .owl-theme .owl-nav {
    margin-top: 0 !important;
    position: absolute;
    right: 7px;
    top: -95px;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
}
.study-area .owl-theme .owl-nav .owl-prev, 
.study-area .owl-theme .owl-nav .owl-next {
    margin: 0 5px;
}
.study-area .owl-theme .owl-nav .owl-prev i, 
.study-area .owl-theme .owl-nav .owl-next i {
    font-size: 20px;
    color: var(--white-color);
    border: 1px solid var(--white-color);
    width: 50px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}
.study-area .owl-theme .owl-nav .owl-next {
    right: -50px;
    left: auto;
}

.single-study {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    padding: 20px;
    margin-bottom: 30px;
}
.single-study i {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 20px;
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #1f5595;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}
.single-study h3 {
    font-size: 23px;
    margin-bottom: 14px;
}
.single-study h3 a {
    color: var(--heading-color);
    -webkit-transition: var(--transition);
    transition: var(--transition);
}
.single-study p {
    -webkit-transition: var(--transition);
    transition: var(--transition);
}*/

/*

Study Area Style
======================================================*/
.study-area {
  padding: 60px 0 60px;
}

.blog-post {
  border-left: 3px solid #ed1c24;
  background: #fff;
}

.news-section {
  background: aliceblue;
  padding: 60px 0 60px 0;
}

.space-ptb {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-bottom a {
  color: #ed1c24;
}

.footer-section {
  background-position: center;
  background-size: cover;
}

.counter-section {
  background: #ed1c24;
}

.counter {
  border-right: 1px solid #fff;
}

.counter:last-child {
  border: none;
}

.footer-title {
  border-bottom: 1px solid #ed1c24;
  padding: 0px 0 5px;
  width: fit-content;
}

.study-area .study-slider .team img {
  width: 100%;
}

.about-area {
  padding: 60px 0 60px 0;
}

.about-section:before {
  background: rgba(255, 255, 255, 0.7);
  ;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*.footer-section{
    padding:40px 0 40px 0;
}*/
.pb-40 {
  padding-bottom: 40px;
}

.about-section {
  background-size: cover;
  background-attachment: fixed;
}

.vision-mission-section {
  padding: 60px 0 60px 0;
  background: #ed1c24;
}

.mision-vision-text li {
  color: #fff;
}

.vision-mission-section .vision-nission-box .media-body h3 {
  color: #fff;
}

.research-development-section,
.facilitators-development-section,
.schoollife-overview-section,
.sport-section,
.music-dance-section,
.creative-arts-section,
.clubs-societies-section,
.educational-trips-excursions-section,
.social-outreach-section,
.facilities-section,
.gallery-section,
.refer-friend-section,
.news-event-section,
.news-event-detail-section,
.careers-section {
  padding: 60px 0 60px 0;
}

.research-development-section p strong {
  color: #ed1c24;
}

.right-angel-arrow li {
  display: flex;
}

.box-odd {
  background: #f1f1f1;
  padding: 15px;
  border-left: 2px solid #ed1c24;
}

.box-even {
  background: #f1f1f1;
  padding: 15px;
  border-right: 2px solid #ed1c24;
}

.facilities-section .events-content a {
  font-size: 26px;
  color: #ed1c24;
}

.box-border-bottom {
  border-bottom: 2px solid #ed1c24;
}

.news-event-section .blog-post {
  border-left: none;
  border-bottom: 1px solid #ed1c24;
}

.news-event-section .blog-post .blog-contant {
  padding: 15px;
  padding-left: 25px;
}

.news-event-section .blog-post .blog-contant h3 {
  color: #ed1c24;
}

.contact-us-section .contact-icon {
  font-size: 30px;
}

.contact-us-section h3 {
  color: #ed1c24;
}

.bg-dark-blue {
  background-color: #ed1c24;
}

.bg-green {
  background-color: #ed1c24;
}

.bg-dark-gray {
  background-color: #f0f0f3;
}

.year-title {
  border-bottom: 2px solid #ed1c24;
  width: fit-content;
  color: #ed1c24;
  margin-bottom: 30px;
}

.award-section .award-img {
  margin-bottom: 10px;
}

.award-section h5 {
  color: #ed1c24;
  font-size: 22px;
  border-top: 1px solid #d0cfcf;
  padding-top: 8px;
}

.award-section .award h4 i {
  color: orange;
  padding-right: 12px;
}

.award-section .award h4 {
  border-bottom: 1px dashed #c0b8b8;
  padding: 10px 0;
}

.careers-section .current-opening h4 {
  color: #ed1c24;
}

.careers-section .location {
  color: #ed1c24;
  font-size: 22px;
}

.careers-section .experience-qualification {
  color: #ed1c24;
  font-size: 22px;
}

.careers-box {
  padding: 25px 25px;
  border: 1px dashed #ed1c24;
  border-radius: 5px;
}

.team-info:hover {
  background-color: #ed1c24;
  color: #fff;
}

.vg {
  background-color: #ed1c24;
}

.vg h5 {
  color: #fff;
  padding: 10px;
  font-size: 19px;
}

.vg:hover {
  color: #fff;
  background-color: #212529;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}



/* banner caption css */


.caption h1 {
  font-size: 48px; /* Default for large screens */
  color: #fff;
  margin: 0;
}

.caption p {
  font-size: 25px; /* Default for large screens */
  color: #fff;
  margin: 0;
}

/* For tablets */
@media screen and (max-width: 768px) {
  .caption h1 {
    font-size: 20px !important; /* Slightly smaller on tablets */
  }

  .caption p {
    font-size: 20px !important; /* Adjust the font size for smaller screens */
  }
}

/* For mobile devices */
@media screen and (max-width: 480px) {
  .caption h1 {
    font-size: 20px !important; /* Further reduced font size on mobile */
  }

  .caption p {
    font-size: 15px !important; /* Smaller font size on mobile */
  }
}

/* For very small screens */
@media screen and (max-width: 320px) {
  .caption h1 {
    font-size: 15px !important; /* Even smaller on very small screens */
  }

  .caption p {
    font-size: 14px !important; /* Smallest for very small mobile screens */
  }
}


/* header enquiry tab */

.enquire-tab
{
  background-color: black;
  
  border-radius: 15px;
}
.enquire-tab a
{
 
  color: white !important;
 
}

/* mobile view enquirie css */


.enquire-tab-mobile
{
  background-color: black;
  
  border-radius: 5px;
  padding: 6px;
  width: 100px;
}
.enquire-tab-mobile a
{
 
  color: white !important;
 
}


/* the clay learning system css */

/* Media query for mobile devices */
@media (max-width: 768px) {
  .section-title h2 {
    font-size: 5vw; /* Adjust font size for tablets/smaller devices */
  }

  .section-title p {
    font-size: 3.5vw; /* Adjust font size for tablets/smaller devices */
  }
}

/* Media query for very small devices */
@media (max-width: 480px) {
  .section-title h2 {
    font-size: 6vw; /* Further adjust font size for mobile devices */
  }

  .section-title p {
    font-size: 4vw; /* Further adjust font size for mobile devices */
  }
}


/* enquiriy now css */


.createCatelogDiv {
  bottom: 37%;
  left: 98%;
  position: fixed;
  z-index: 99999999;
  background: #ed1c24;

  border-radius: 10px;
}

.createCatelogDiv a {
  color: #fff;
  text-orientation: upright;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
}

.createCatelogDiv a:hover {
  color: #fff;
}

.catalogue-vertical {
  color: #fff;
  text-orientation: upright;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
  border: none;
  background: transparent;
}


@media (max-width: 768px) {
  .createCatelogDiv {
    left: 96%; /* Push it more inside the view for smaller screens */
   
  }
}

/* Responsive design for very small mobile screens (max-width 480px) */
@media (max-width: 480px) {
  .createCatelogDiv {
    left: 90%; /* Push it even more inside for very small screens */
  
  }

  
}

.admission-overview
{
  font-size: 15px;
}

.learning-system
{
  font-size: 14px;
}

.syllabus-height
{
  font-size: 15px;
}

/* Container for the image */
.img-container {
  background-color: #f0f0f0; /* Light gray background color */
  padding: 10px;             /* Add some padding around the image */
  border-radius: 15px;        /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the image */
.img-styled {
  border-radius: 10px;        /* Border radius to the image itself */
  /* object-fit: cover;          Ensure the image covers its container without distortion */
  max-height: 350px;          /* Set a maximum height for the image */
}



/* the learning system css */

.team-img {
  position: relative;
  overflow: hidden; /* To keep the overlay inside the image container */
}

.team-img img {
  position: relative;
  z-index: 1; /* Ensures the image is above the background overlay */
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #bd3a3a;
  opacity: 0.9; /* Adjust opacity for desired overlay intensity */
  z-index: 0;
}
